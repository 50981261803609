import {
    format,
    getTimezoneOffset,
    utcToZonedTime,
    zonedTimeToUtc
} from "date-fns-tz";
import i18n from "../../i18n";
import { formtNumberByLanguage, abbreviateNumber } from "util/helpers/number.helpers";

export const getDatePattern = (language, withTime = true, withUppercase = false) => {
    const time = " HH:mm:ss";
    let pattern = "yyyy/MM/dd";
    switch (language) {
        case "en":
            pattern = "MM/dd/yyyy";
            break;
        case "fr":
            pattern = "dd/MM/yyyy";
            break;
        case "es":
            pattern = "dd/MM/yyyy";
            break;
        case "pl":
            pattern = "dd/MM/yyyy";
            break;
        case "de":
            pattern = "yyyy/MM/dd";
            break;
        case "ar":
            pattern = "yyyy/MM/dd";
            break;
        default:
            break;
    }
    if (withUppercase) pattern = pattern.toUpperCase();
    if (withTime) pattern += time;
    return pattern;
};

export const formatDate = (date, language, withTime = true) => {
    const pattern = getDatePattern(language, withTime);
    return format(new Date(date), pattern);
};

export const getClientTimezone = () => {
    const storedTimezone = localStorage.getItem("sr-tz");
    if (
      storedTimezone
      && Intl.supportedValuesOf("timeZone").includes(storedTimezone)
    ) {
        return storedTimezone;
    }
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const localToZonedTime = (localDate, timeZone) => {
    const utcDate = zonedTimeToUtc(localDate, Intl.DateTimeFormat().resolvedOptions().timeZone);
    return utcToZonedTime(utcDate, timeZone);
}

export const defaultManDayHours = 8;

export const defaultDaysPerYear = 228;

export const formatDateByLanguage = (
    date,
    withTime = true,
    customFormat = null,
) => {
    if (!date) return null;
    const { language } = i18n;
    const hasTime = date.toString().includes("T");
    if (customFormat !== null) date = format(date, customFormat);
    if (!withTime) return formatDate(date, language, withTime);
    return formatDate(date, language, hasTime);
};

export const formatDatePickerByLanguage = (withTime = true) => {
    const { language } = i18n;
    return getDatePattern(language, withTime);
};

export function secondsToTime(s, t) {
    const time = new Date(s * 1000);
    const hours = time.getUTCHours();
    const minutes = time.getUTCMinutes();
    const seconds = time.getUTCSeconds();

    return (`${hours} ${t("timeUnite.hrs")} ${minutes} ${t(
        "timeUnite.min",
    )}  ${seconds} ${t("timeUnite.sec")}`).toLowerCase();
}
export function hoursDifference(date, t) {
    return `${Math.ceil((new Date() - new Date(date)) / 36e5)} ${t("timeUnite.hrs")}`;
}

export function secondsToFTPTime(hours, md, ftp, t) {
    const hoursFormated = abbreviateNumber(hours);
    const manDays = hours / md;
    const days = abbreviateNumber(manDays);
    const years = abbreviateNumber(days / ftp);
    if (hours < md) {
        return `${formtNumberByLanguage(hoursFormated)} ${t("timeUnite.hrs")}`;
    }
    if (manDays < ftp) {
        return `${formtNumberByLanguage(days)} ${t("timeUnite.manDay")}`;
    }
    if (manDays >= ftp) {
        return `${formtNumberByLanguage(years)} ${t("timeUnite.fte")}`;
    }
    return `${formtNumberByLanguage(hoursFormated)} ${t("timeUnite.hrs")}`;
}

export function secondsToFTPTimeLabel(hours, md, ftp, t) {
    if (hours < md) {
        return secondsToFTPTime(hours, md, ftp, t);
    }
    return `${secondsToFTPTime(hours, md, ftp, t)} (${formtNumberByLanguage(hours)}${t("timeUnite.hrs")})`;
}

export const getDate = (dateIn) => {
    const dt = new Date(dateIn);
    const pattern = "yyyy/MM/dd HH:mm:ss";
    return format(dt, pattern);
};

export const formatTimezone = (timezone, offset, time) => {
    if (offset === null) {
        offset = getTimezoneOffset(timezone, new Date()) / 3.6e6;
    }
    const offsetLabel = `UTC${offset >= 0 ? "+" : ""}${offset}`;
    return `${offsetLabel} • ${time}`;
};

export const formatDateParam = (dateString, pattern) => {
    if (dateString) {
        if (pattern) return format(new Date(dateString), pattern);
        return format(new Date(dateString), "yyyy-MM-dd");
    }
    return null;
};

export const formatDateTimeIsoParam = (dateString) => {
    if (dateString) {
        return format(new Date(dateString), "yyyy-MM-dd'T'HH:mm:ss");
    }
    return null;
};

export const initialDays = [
    {
        id: 1, label: "Mon", value: "1", checked: true,
    },
    {
        id: 2, label: "Tue", value: "2", checked: true,
    },
    {
        id: 3, label: "Wed", value: "3", checked: true,
    },
    {
        id: 4, label: "Thu", value: "4", checked: true,
    },
    {
        id: 5, label: "Fri", value: "5", checked: true,
    },
    {
        id: 6, label: "Sat", value: "6", checked: true,
    },
    {
        id: 7, label: "Sun", value: "7", checked: true,
    },
];

export const noDaySelected = (days) => days?.filter((day) => day?.checked).length === 0;

export const allDaysSelected = (days) => days?.filter((day) => day?.checked).length === 7;

export const joinDays = (days, t) => days
    ?.filter((d) => d.checked)
    ?.map((d) => (t ? t(d.label) : d.value))
    .join(",");

export const getDateAndTime = (timeString, pattern = "HH:mm:ss") => {
    const [datePart, timePart] = format(timeString, `${formatDatePickerByLanguage(false)} ${pattern}`).split(" ");
    return { datePart, timePart };
};

export const DEFAULT_FROM_TIME = "00:00:00";

export const DEFAULT_TO_TIME = "23:55:00";

export const parseTimeString = (timeString) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    return new Date(1970, 0, 1, hours, minutes, 0);
  };
